<template>
  <div class="custom">
    <!-- 背景图 -->
    <div class="img-back">
      <img src="/static/custom.png" alt="" />
    </div>
    <div class="content">
      <div class="custom-form">
        <div class="custom-form-title">
          定 制 信 息
        </div>
        <div class="custom-form-list">
          <el-input
            v-model="form.phone"
            type="number"
            placeholder="请输入手机号"
          ></el-input>
        </div>
        <div class="custom-form-list">
          <div class="custom-form-list-title">所在城市</div>
          <el-cascader
            v-model="form.city"
            :options="citys"
            placeholder="请选择所在城市"
          >
          </el-cascader>
        </div>
        <div class="custom-form-list">
          <div class="custom-form-list-title">您的预算</div>
          <div class="custom-form-list-radio-wraper">
            <el-radio v-model="form.radio" label="20万元以下"
              >20万元以下</el-radio
            >
            <el-radio v-model="form.radio" label="20-40万元"
              >20-40万元</el-radio
            >
            <el-radio v-model="form.radio" label="40-60万元"
              >40-60万元</el-radio
            >
            <el-radio v-model="form.radio" label="60万元以上"
              >60万元以上</el-radio
            >
          </div>
        </div>
        <div class="custom-form-list">
          <div class="custom-form-list-title">您的品牌倾向（可多选）</div>
          <el-checkbox-group v-model="form.brands" style="margin:9px 0">
            <el-checkbox label="尊贵德系"></el-checkbox>
            <el-checkbox label="轻奢美系"></el-checkbox>
            <el-checkbox label="舒适日系"></el-checkbox>
          </el-checkbox-group>
        </div>
        <div class="custom-form-list">
          <div class="custom-form-list-title">您的常用车场景（可多选）</div>
          <el-checkbox-group v-model="form.scene" style="margin:9px 0">
            <el-checkbox label="城市代步"></el-checkbox>
            <el-checkbox label="商务接待"></el-checkbox>
            <el-checkbox label="户外探索"></el-checkbox>
            <el-checkbox label="家庭出行"></el-checkbox>
          </el-checkbox-group>
        </div>
        <div class="submit" @click="addCustomizeRecForPc">预约专属服务顾问</div>
      </div>
    </div>
  </div>
</template>

<script>
import { addressList, addCustomizeRecForPc } from "@/api/index.js";
export default {
  data() {
    return {
      baseurl: "https://www.yuexinche.com",
      form: {
        phone: "",
        city: [],
        radio: "",
        brands: [],
        scene: [],
      },
      citys: [],
    };
  },
  mounted() {
    this.addressList();
  },
  methods: {
    addressList() {
      addressList({}).then((res) => {
        if (res.code == 0) {
          this.citys = res.data.map((item) => {
            return {
              value: item.value,
              label: item.value,
              children: item.children.map((e) => {
                return {
                  value: e.value,
                  label: e.value,
                };
              }),
            };
          });
        }
      });
    },
    addCustomizeRecForPc() {
      if (!this.form.phone) {
        this.$message({
          message: "请填写手机号",
          type: "info",
        });
        return;
      }
      const reg = /^1(?:3[0-9]|4[5-9]|5[0-9]|6[12456]|7[0-8]|8[0-9]|9[0-9])[0-9]{8}$/;
      if (!reg.test(this.form.phone)) {
        return this.$message.info("请输入正确的手机号");
      }
      if (this.form.city.length == 0) {
        this.$message({
          message: "请选择所在城市",
          type: "info",
        });
        return;
      }
      if (!this.form.radio) {
        this.$message({
          message: "请选择您的预算",
          type: "info",
        });
        return;
      }
      if (this.form.brands == 0) {
        this.$message({
          message: "请选择您的品牌倾向",
          type: "info",
        });
        return;
      }
      if (this.form.scene == 0) {
        this.$message({
          message: "请选择您的常用车场景",
          type: "info",
        });
        return;
      }
      addCustomizeRecForPc({
        mobile: this.form.phone,
        city: this.form.city[1],
        budget: this.form.radio,
        brand: this.form.brands.join(","),
        scene: this.form.scene.join(","),
      }).then((res) => {
        if (res.code == 0) {
          this.$message({
            message: res.msg,
            type: "success",
          });
          this.form = {
            phone: "",
            city: [],
            radio: "",
            brands: [],
            scene: [],
          };
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.custom {
  width: 100%;
  .img-back {
    width: 100%;
    min-width: 1200px;
    margin: 0 auto;
    img {
      width: 100%;
      display: inherit;
    }
  }
  .content {
    font-weight: 500;
    width: 100%;
    min-width: 1200px;
    margin: 0 auto;
    overflow: hidden;
    background: #111215;
    display: flex;
    align-items: center;
    justify-content: center;
    .custom-form {
      width: 600px;
      height: 800px;
      padding-top: 80px;
      .custom-form-title {
        font-size: 26px;
        text-align: CENTER;
        color: #ffffff;

        margin-bottom: 50px;
      }
      .custom-form-list {
        border-bottom: 1px solid #e5e5e5;
        .custom-form-list-title {
          color: #ffffff;
          font-size: 18px;
          margin-top: 15px;
        }
        /deep/ .el-input {
          width: 100%;
          height: 32px;
          .el-input__inner {
            background-color: rgba(0, 0, 0, 0) !important;
            height: 32px;
            border: none;
            color: #fff;
            padding: 0;
          }
          input[type="number"] {
            -moz-appearance: textfield;
          }
          input[type="number"]::-webkit-inner-spin-button,
          input[type="number"]::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
        }
        .custom-form-list-radio-wraper {
          width: 100%;
          margin: 9px 0;
          /deep/ .el-radio {
            margin-right: 15px;
            .el-radio__inner {
              border-radius: 2px;
            }
            .is-checked {
              .el-radio__inner {
                &::after {
                  transform: rotate(45deg) scaleY(1);
                  box-sizing: content-box;
                  content: "";
                  border: 1px solid #fff;
                  border-left: 0;
                  border-top: 0;
                  height: 7px;
                  left: 4px;
                  position: absolute;
                  top: 1px;
                  width: 3px;
                  border-radius: 0;
                  background-color: rgba(0, 0, 0, 0);
                }
              }
            }
          }
        }
      }
      .submit {
        width: 175px;
        height: 40px;
        border: 1px solid #ffffff;
        border-radius: 5px;
        line-height: 40px;
        text-align: center;
        color: #ffffff;
        font-size: 16px;
        cursor: pointer;
        margin: 60px auto 0;
        &:hover {
          background-color: rgb(99, 95, 95);
        }
      }
    }
  }
}
</style>
